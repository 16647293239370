import { Fragment } from 'react';

import { generateRandomColor } from '../../utils/random-color-generator';
import { ShoutzText } from '../shoutz-text';
import { ShoutzBackground } from '../shoutz-background';

export const App = () => {
	const queryParams = {};
	const queryParamString = location.search.replace(/^\?/, '');
	if (queryParamString) {
		queryParamString.split('&').map(queryParam => {
			const [key, value] = queryParam.split('=');
			queryParams[key] = value;
		});
	}

	const urlPathInput = location.pathname.replace(/\/$/, '').split('/').filter(Boolean);

	const text = queryParams.text ? queryParams.text : urlPathInput.pop();
	const backgroundColor =
		queryParams?.background === 'random' ? generateRandomColor() : queryParams.background;

	let textColor = queryParams?.color === 'random' ? generateRandomColor() : queryParams.color;

	return (
		<Fragment>
			<ShoutzBackground color={backgroundColor} />
			<ShoutzText text={text} color={textColor} random={queryParams?.random === 'word'} />
		</Fragment>
	);
};
