import { createGlobalStyle } from 'styled-components';

const DEFAULT_COLOR = 'BLACK';

export const Background = createGlobalStyle`
	body {
		background-color: ${({ color }) => (color ? color : DEFAULT_COLOR)};
		height: 100vh;
		text-align: center;
		margin: 0;
		overflow: hidden;
	}
`;
