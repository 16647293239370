import styled from 'styled-components';

const DEFAULT_COLOR = 'WHITE';

export const Text = styled.div`
	font-size: ${({ letterCount }) => (letterCount <= 2 ? 70 : 100 / (letterCount - 1))}vw;
	line-height: 100vh;
	font-family: 'Azeret Mono', monospace;
	color: ${({ color }) => (color ? color : DEFAULT_COLOR)};
	display: flex;
	justify-content: ${({ letterCount }) => (letterCount === 1 ? 'space-around' : 'space-between')};
`;
