import { Text } from './Text';
import { useEffect, useState } from 'react';
import { generateRandomColor } from '../../utils/random-color-generator';

const DEFAUL_TEXT = 'SHOUTZ';

export const ShoutzText = ({ text, color, random = false }) => {
	const [wordIndex, setWordIndex] = useState(0);

	useEffect(() => {
		const isLastWord = wordIndex === words.length - 1;
		const interval = setInterval(
			() => {
				setWordIndex(isLastWord ? 0 : wordIndex + 1);
			},
			isLastWord ? 2000 : 1000
		);

		return () => {
			clearInterval(interval);
		};
	});

	const words = !text ? [DEFAUL_TEXT] : decodeURI(text.toUpperCase()).split(' ');

	return (
		<Text letterCount={words[wordIndex].length} color={random ? generateRandomColor() : color}>
			{words[wordIndex].split('').map((letter, letterIndex) => (
				<span key={`${letter}${letterIndex}`}>{letter}</span>
			))}
		</Text>
	);
};

export default ShoutzText;
